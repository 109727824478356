import { render, staticRenderFns } from "./ConventionsBrowse.vue?vue&type=template&id=7a525cb0&scoped=true&"
import script from "./ConventionsBrowse.vue?vue&type=script&lang=js&"
export * from "./ConventionsBrowse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a525cb0",
  null
  
)

export default component.exports