<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Conventions" subtitle="Browse conventions and view them in other modes." />

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no conventions found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />
				<app-table-column text="Starts" />
				<app-table-column text="Ends" />
				<app-table-column />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="item.date.start | formatDate('Do MMMM YYYY')" />
					<app-table-cell-text :text="item.date.end | formatDate('Do MMMM YYYY')" />
					<app-table-cell-action icon="mode.manager" v-tooltip="'Launch in Manager'" v-on:click="onManagerClick(item.id)" />
					<app-table-cell-action icon="mode.my" v-tooltip="'Launch in My'" v-on:click="onMyClick(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: 'auto 160px 160px 24px 24px',
			endpoint: 'conventions/conventions',
			live: 'conventions/conventions',
			edit: 'Conventions.Browse.Edit',
		}

	},

	methods: {

		onManagerClick: function(id) {

			this.$api.post('launch', {
				convention: id,
				mode: 'manager'
			}).then(function(json) {

				window.open(json.url)

			})

		},

		onMyClick: function(id) {

			this.$api.post('launch', {
				convention: id,
				mode: 'my'
			}).then(function(json) {

				window.open(json.url)

			}, function() {

				this.$notify({
					message: 'Failed to launch, no badges found.',
					type: 'error'
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>